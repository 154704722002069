@use '~@timelog/ui-library/src/assets/styles/variables/spaces';

.widgetWrapper {
  padding-block: spaces.$space-size-4;
  padding-inline: spaces.$space-size-5;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
}

.tips {
  margin-top: auto;
  padding-top: spaces.$space-size-5;
}

.defWrapper {
  display: flex;
  flex-direction: column-reverse;

  > *:not(:last-child) {
    margin-top: spaces.$space-size-1;
  }
}
