$rich-text-max-height: 200;

.staticText {
  max-height: $rich-text-max-height * 1px;
  overflow-x: auto;
}

.showAll {
  max-height: none;
}

:export {
  richTextMaxHeight: $rich-text-max-height;
}
