@use '~@timelog/ui-library/src/assets/styles/variables/colors/colors.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/breakpoints.scss';
@use '~@timelog/ui-library/src/assets/styles/variables/spaces.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/media-queries.scss';
@use '~@timelog/ui-library/src/assets/styles/mixins/resets.scss';
@use 'src/assets/styles/variables/breakpoints.scss' as app-breakpoints;
@use 'src/assets/styles/variables/colors.scss' as app-colors;
@use 'src/assets/styles/variables/sizes.scss' as app-sizes;

.Wrapper {
  position: fixed;
  top: 0;
  z-index: 12;
  width: 100%;
  height: app-sizes.$height-header-with-border;
  background-color: colors.$color-white;
  border-bottom: 1px solid #ddd;
  padding-inline: spaces.$space-size-4;

  @include media-queries.desktop {
    padding-inline: spaces.$space-size-12;
  }

  @include media-queries.laptop {
    padding-inline: spaces.$space-size-6;
  }

  @media print {
    display: none;
  }
}

.Inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  @include media-queries.till-tablet {
    padding-right: app-sizes.$height-header;
  }
}

.Options {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: app-sizes.$height-header;
}

.HomeLink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  margin-right: spaces.$space-size-2;

  @include media-queries.above-tablet {
    height: app-sizes.$height-header;
  }

  &Logo {
    fill: colors.$theme-color-pink;
    width: 24px;
    height: 24px;
  }

  &:focus,
  &:hover {
    .HomeLinkLogo {
      fill: colors.$theme-color-pink-dark;
    }
  }
}

.SiteStatusIndicator {
  margin-left: spaces.$space-size-3;
}
